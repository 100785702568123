// @flow
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import LevelledRenderer from '../components/renderers/levelledRenderer'
import TextRenderer from '../components/renderers/textRenderer'
import '../css/main.min.css'

type Props = {
  data: Object,
}

class View extends React.Component<Props> {
  render() {
    const { pageData, allMarkdownRemark } = this.props.data
    const { frontmatter: pageFrontmatter, html } = pageData
    return (
      <Layout
        data={{
          allMarkdownRemark: allMarkdownRemark,
        }}
      >
        {pageFrontmatter.yaml === true ? (
          <LevelledRenderer pageData={pageFrontmatter} html={html} />
        ) : (
          <TextRenderer data={pageFrontmatter} html={html} />
        )}
      </Layout>
    )
  }
}

export default function FrameworkView({ data }: Object) {
  return <View data={data} />
}

export const pageQuery = graphql`
  query($path: String, $isYaml: Boolean!) {
    allMarkdownRemark: allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
    pageData: markdownRemark(
      frontmatter: { homepage: { eq: false }, path: { eq: $path } }
    ) {
      html
      frontmatter {
        path
        title
        sidebarTitle
        sidebarGroup
        yaml
        levels {
          name
          index
          topics {
            title
            subtitle
            content
            path
          }
        }
        topics @include(if: $isYaml) {
          name
          title
          content {
            title
            level
            criteria
            path
            exampleCriteria {
              criteria
              examples
            }
          }
        }
      }
    }
  }
`
