// @flow
import * as React from 'react'
import Header from '../header'
import { FrameworkGroup, PageBanner } from '../styles'
import ComingSoonBanner from '../../images/banners/coming-soon-banner.png'

import Accordion from '../accordion'

type Props = {
  pageData: Object,
  html: Object,
}

type State = {
  level: ?number,
  path: 'Lead' | 'Principal' | null,
}

export default class LevelledRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      level: 0,
      path: null,
    }
  }

  handleClick = (val: ?number) => (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    let newPath = null
    if (val !== null && val !== undefined && val > 3) {
      newPath =
        this.props.pageData.sidebarGroup === 'product' ? 'Lead' : 'Principal'
    }
    this.setState({
      level: val,
      path: newPath,
    })
  }

  handlePathClick = (val: 'Principal' | 'Lead') => (
    event: SyntheticUIEvent<>,
  ) => {
    event.preventDefault()
    this.setState({
      ...this.state,
      path: val,
    })
  }

  renderPageHeader() {
    const { pageData } = this.props
    const { level, path } = this.state

    return (
      <Header
        onClickHandler={this.handleClick}
        onPathClickHandler={this.handlePathClick}
        pageData={pageData}
        activeLevel={level}
        activePath={path}
      />
    )
  }

  renderFramework() {
    const { pageData } = this.props
    const { level, path } = this.state
    let content = pageData.levels.map(
      levelDetails =>
        levelDetails.index === level &&
        this.createLevelContent(levelDetails, path),
    )
    return <>{content}</>
  }

  createLevelContent = (
    levelDetails: Object,
    path: 'Principal' | 'Lead' | null,
  ) => {
    if (
      path !== null &&
      (levelDetails.topics === null ||
        levelDetails.topics.filter(topic => topic.path === path).length === 0)
    )
      return <PageBanner key={levelDetails.name} src={ComingSoonBanner} />
    return (
      <FrameworkGroup key={levelDetails.name}>
        {levelDetails.topics.map(topic => {
          if (
            levelDetails.index <= 3 ||
            (levelDetails.index > 3 && topic.path === path)
          )
            return (
              <Accordion
                title={topic.title}
                content={topic.content}
                key={`${levelDetails.name}-${topic.title}`}
              />
            )
        })}
      </FrameworkGroup>
    )
  }

  render() {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderFramework()}
      </>
    )
  }
}
