// @flow
import * as React from 'react'
// import styled from 'styled-components'
import {
  LevelsTextWrapper,
  LevelsContainer,
  FrameworkHeader,
  FrameworkTitleGroup,
  PageBanner,
  LevelHeader,
  Title,
  RoleTab,
  RoleTabItem,
} from './styles'
import LevelItem from './level-item'
import ProductBanner from '../images/banners/product-banner.png'
import EngineerBanner from '../images/banners/engineer-banner.png'
import DesignBanner from '../images/banners/designer-banner.png'
import Placeholder from '../images/banners/placeholder-banner.png'
import styled from 'styled-components'

type Props = {
  onClickHandler: (?number) => (SyntheticUIEvent<>) => mixed,
  onPathClickHandler: ('Principal' | 'Lead') => (SyntheticUIEvent<>) => mixed,
  pageData: Object,
  activeLevel: ?number,
  activePath: ?string | null,
}

type LevelProps = {
  onClickHandler: (?number) => (SyntheticUIEvent<>) => mixed,
  pageData: Object,
  activeLevel: ?number,
}

const PathTab = styled(RoleTab)`
  min-width: auto;
  width: fit-content;
  margin-top: 16px;
`

const renderLevels = ({
  onClickHandler,
  pageData,
  activeLevel,
}: LevelProps) => {
  const toRender = []
  for (let level of pageData.levels) {
    toRender.push(
      <LevelItem
        onClickHandler={onClickHandler}
        key={`${level.index}`}
        index={level.index}
        title={level.name}
        active={activeLevel != null && level.index === activeLevel}
      ></LevelItem>,
    )
  }
  return toRender
}

const Header = ({
  onClickHandler,
  onPathClickHandler,
  pageData,
  activeLevel,
  activePath,
}: Props) => {
  let imageSrc = Placeholder
  if (pageData.sidebarGroup === 'product') imageSrc = ProductBanner
  if (pageData.sidebarGroup === 'engineering') imageSrc = EngineerBanner
  if (pageData.sidebarGroup === 'design') imageSrc = DesignBanner
  const renderTitle = () => {
    if (activeLevel === undefined || activeLevel === 0)
      return <LevelHeader>Overview</LevelHeader>
    if (activeLevel !== null && activeLevel !== undefined && activeLevel > 0) {
      let title = `Level ${activeLevel}`
      if (activePath !== null && activePath !== undefined) {
        if (activeLevel === 4) title += `: ${activePath}`
        if (activeLevel === 5) title += `: Senior ${activePath}`
      }
      return <LevelHeader>{title}</LevelHeader>
    }
  }
  return (
    <FrameworkHeader>
      <FrameworkTitleGroup>
        <Title small>{pageData.title}</Title>
      </FrameworkTitleGroup>
      <PageBanner src={imageSrc} />
      <LevelsContainer>
        <LevelsTextWrapper>
          {renderLevels({ onClickHandler, pageData, activeLevel })}
        </LevelsTextWrapper>
      </LevelsContainer>
      {renderTitle()}
      {activeLevel !== null &&
        activeLevel !== undefined &&
        activeLevel > 3 &&
        pageData.sidebarGroup !== 'product' && (
          <PathTab>
            <RoleTabItem
              active={activePath === 'Principal'}
              onClick={onPathClickHandler('Principal')}
            >
              Principal
            </RoleTabItem>
            <RoleTabItem
              active={activePath === 'Lead'}
              onClick={onPathClickHandler('Lead')}
            >
              Lead
            </RoleTabItem>
          </PathTab>
        )}
    </FrameworkHeader>
  )
}

export default Header
